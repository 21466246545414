import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import SEO from '../components/SEO'

const AboutUS = ({ data: { about } }) => {
  return (
    <>
      <SEO page="about" />
      <section className="about">
        <div className="about-hero">
          <Img fixed={about.childImageSharp.fixed} alt="about-us" />
          <div className="about-text">
            <hr />
            <h2>Something about Cboxera.com</h2>
            <p>High Speed Downloads with one account!</p>
          </div>
        </div>
        <div className="row spacing-70">
          <div className="col-sm-8 center-block">
            <p>
              Cboxera.com was established in 2014 with a vision to give users a
              service which can help them overcome the download restrictions
              being imposed by various filehost and give high-quality online
              services.
            </p>
            <p>
              {' '}
              Our team consist specialist from various fields of Programming,
              Web-Designing. We try to keep match with new demands from users.
              The main principle of our site is satisfying the needs of the
              Internet users hosting and sharing their data. Therefore, we make
              every effort to bring to our users the most innovative, effective
              and user friendly services we can.
            </p>
          </div>
        </div>
        <div className="row spacing-50">
          <div className="col-sm-8 center-block">
            <h3>We @ CboxEra</h3>
            <p>
              Cboxera.com is a service providing services gateway (Free and
              paid) that allows users to download files from various filehosting
              sites practically without limits and maintaining their privacy on
              the Internet. We use our own powerful servers each with a channel
              of at-least 1Gbps to redirect traffic from filehosting to our
              users.
            </p>
            <p>
              It also allows users to download files from filehosting services
              which are prohibited in their country. The main advantages of our
              service Includes high speed data transfer, instant download, the
              possibility of parallel downloads, anonymity during downloading,
              resumption of interrupted downloads, support of download managers
              and no advertising (for Vip Users)..
            </p>
          </div>
        </div>
      </section>
    </>
  )
}

AboutUS.propTypes = {
  data: PropTypes.object.isRequired,
}

export const aboutusImages = graphql`
  query {
    about: file(relativePath: { eq: "about/about_bg.jpg" }) {
      childImageSharp {
        fixed(width: 1792, height: 679) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`

export default AboutUS
